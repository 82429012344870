@import '../../../styles/_vars';

.column-section {
  h2 {
    text-align: center;
  }
  &__text {
    display: grid;
    margin-top: $gutter;
    grid-template-columns: 1fr 1fr;
    grid-gap: $gutter-large;
  }
  &__button {
    margin-top: $gutter;
    display: flex;
    justify-content: center;
  }
}

@media(max-width: $breakpoint) {
  .column-section {
    h2 {
      text-align: left;
    }
    &__text {
      grid-template-columns: 1fr;
      grid-gap: $gutter;
    }
  }
}
