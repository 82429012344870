@import '../../../styles/_vars';

.services {
  &__title {
    margin-bottom: $gutter;
  }
  &__grid {
    display: flex;
    justify-content: space-between;
    .services {
      &__service {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 $gutter/2;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          .overlay {
            opacity: 1;
          }
          ul > li, h3 {
            color: $color-accent;
          }
          .service__title {
            opacity: 1;
          }
        }
      }
    }
  }
}


.service {
  &__image {
    width: 100%;
    padding-top: 145%;
    position: relative;
    overflow: hidden;
    margin-bottom: $gutter;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: 2;
    }
    .overlay {
      opacity: 0;
      transition: opacity .3s;
      z-index: 3;
    }
    .service__title {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      padding: $gutter/2;
      z-index: 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity .3s;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-main;
    h3 {
      margin-bottom: 1rem;
      transition: color .3s;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        color: $color-main;
        margin: .3em 0;
        transition: color .3s;
      }
    }
  }
}

@media(max-width: $breakpoint) {
  .services {
    &__grid {
      flex-wrap: wrap;
      .services__service {
        width: 100%;
        margin: $gutter/2 0;
      }
    }
  }
  .service {
    &__image {
      margin: 0;
      height: 0;
      padding-top: 30%;
      .overlay {
        opacity: 1 !important;
        background-color: rgba(0,0,0,.4);
      }
    }
    &__title {
      opacity: 1 !important;
    }
  }
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: scale(.85);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.services__animation {
  .services__grid {
    .services__service {
      animation-name: reveal;
      animation-duration: 0.5s;
      animation-fill-mode: both;
      &:nth-child(2) {
        animation-delay: .3s;
      }
      &:nth-child(3) {
        animation-delay: .6s;
      }
      &:nth-child(4) {
        animation-delay: .9s;
      }
    }
  }
}
