@import '../../../styles/_vars';

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

.splitted-section {
  background-color: $color-white;
  width: 100%;
  position: relative;
  &--alt-color {
    background-color: $color-main;
    padding: $gutter-large 0;
    margin-bottom: 0;
    .splitted-section__grid {
      box-shadow: $shadow-dark;
    }
    .splitted-section__text {
      padding: $gutter-large;
    }
  }
  &__grid {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    &--reverse {
      .splitted-section__text {
        order: 2;
        padding-right: 0;
        padding-left: $gutter-large;
      }
      .splitted-section__image {
        order: 1;
      }
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: $gutter-large;
    padding-left: 0;
    background-color: $color-white;
    h2 {
      margin-bottom: $gutter;
    }
    .button {
      margin-top: $gutter;
    }
  }
  &__image {
    position: relative;
    padding-top: 100%;
    img {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    &--rounded {
      border-radius: 50%;
      overflow: hidden;
      max-height: 0;
      align-self: center;
    }
  }
  &__flower {
    position: absolute;
    bottom: 0;
    right: 10%;
    transform: translateX(25%);
    width: 12%;
    height: auto;
    z-index: 1;
  }
}

@media(max-width: $breakpoint) {
  .splitted-section{
    &__grid {
      grid-template-columns: 1fr;
      &--reverse .splitted-section__text {
        padding: 0;
        padding-top: $gutter;
      }
    }
    &--alt-color {
      .splitted-section__text {
        padding: $gutter;
      }
    }
    &__flower {
      width: 24%;
    }
    &__text {
      padding: $gutter 0;
    }
  }
}
