@import '../../../styles/_vars';

.introduction {
  margin: $gutter-large $margin;
  .parallax-banner {
    margin-top: $gutter;
  }
}

@media(max-width: $breakpoint) {
  .introduction {
    margin: $gutter $margin;
    h1 {
      margin-bottom: $gutter;
    }
  }
}
