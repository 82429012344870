@import '../../styles/_vars';

.footer {
  width: 100%;
  &__top-bar {
    background-color: $color-white;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__bottom-bar {
    width: 100%;
    background-color: $color-main;
    display: flex;
    justify-content: center;
    p, a {
      color: white;
      font-size: .8rem;
      text-align: center;
    }
    a {
      text-decoration: underline;
    }
  }
  &__link {
    margin: 0 1rem;
  }
}

@media (max-width: $breakpoint) {
  .footer {
    &__bottom-bar {
      flex-direction: column;
      p, a, .footer__social {
        padding: $gutter/2 0;
      }
    }
    &__social {
      display: flex;
      justify-content: center;
      a {
        padding: 0;
      }
      svg {
        height: 1rem;
        width: 1rem;
        margin: 0 1rem;
      }
    }
  }
}
