@import '../../../styles/_vars';

.header__topbar {
  background-color: $color-main;
  padding: .2rem $margin;
  display: flex;
  height: 1.6rem;
}

.header__contact {
  flex: 1;
  display: flex;
  a,li {
    margin-right: 2rem;
    color: $color-white;
    font-size: .8rem;
  }
  img {
    width: 16px;
    margin-right: .5rem;
    vertical-align: bottom;
  }
}

.header__social {
  display: flex;
  align-items: center;
  a {
    height: 100%;
    display: block;
    margin-left: 1em;
  }
  img {
    width: 16px;
    vertical-align: middle;
  }
}

@media (max-width: $breakpoint) {
  .header__topbar {
    display: none;
  }
}
