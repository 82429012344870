@import '../../../styles/_vars';

.mentions-legales {
  width: 80%;
  margin: 0 $margin;
  .introduction {
    width: 100%;
    margin: $gutter-large 0 !important;
  }
  p {
    line-height: 1.2em;
    margin: $gutter 0;
  }
  a {
    text-decoration: underline;
  }
}
