// Colors
$color-main: #a184b7;
$color-dark: #241052;
$color-white: #fff;
$color-grey: #6e6e6e;
$color-accent: #d0184d;
$color-overlay: #180d5694;
$color-button: #634b7f;
$color-title: #a184b7;

// Shadows
$shadow-light: 2px 2px 18px #634b7f80;
$shadow-dark: 2px 2px 18px #634b7fb3;
$shadow-dark-large: 4px 4px 36px #634b7fb3;

// Gutters
$gutter: 30px;
$gutter-large: 50px;
$margin: 10%;

$radius: 10px;

// Responsive
$breakpoint: 1150px;
