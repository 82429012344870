@import './_vars';


// Fonts

@font-face {
  font-family: 'Mathilde';
  src: url('../fonts/mathilde.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-Medium.ttf');
  font-weight: bold;
  font-style: normal;
}


// Global

* {
  box-sizing: border-box;
  margin: 0;
  &:focus {
    outline: none;
  }
}

body {
  overflow-x: hidden;
  height: 100vh;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-family: 'Mathilde';
  font-size: 10rem;
  text-align: center;
  color: $color-main;
  font-weight: normal;
}

h2 {
  font-family: 'Mathilde';
  font-size: 5rem;
  line-height: 4rem;
  text-align: left;
  color: $color-main;
  font-weight: normal;
}

h3 {
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 1.2em;
  color: $color-main;
}

h4 {
  font-family: 'Mathilde';
  font-size: 4em;
  text-align: center;
  color: $color-main;
  font-weight: normal;
}

body, li, a, p, button {
  font-family: 'Raleway';
  font-size: 1em;
  color: $color-grey;
  font-weight: normal;
}

p {
  line-height: 2.3em;
  text-align: justify;
}

.tarifs {
  font-family: 'Raleway';
  font-size: 3.5em;
  text-align: center;
  font-weight: normal;
}

section {
  width: 80%;
  margin: auto;
  margin-bottom: $gutter-large;
}

// Utils

.center {
  text-align: center;
}

.white {
  color: $color-white;
}

.overlay {
  position: absolute;
  background: rgba(0,0,0,.6);
  top: 0; right: 0; bottom: 0; left: 0;
}

@media (max-width: $breakpoint) {

  body, li, a, p, button {
    font-size: 1.05em;
    line-height: 1.5em;
  }

  h1 {
    font-size: 5rem;
    line-height: 4rem;
  }
  h2 {
    font-size: 4rem;
    line-height: 3rem;
  }
}
