@import '../../../styles/_vars';

.temoignages {
  width: 100%;
  position: relative;
  &__text {
    padding: $gutter-large;
    width: 80%;
    margin: $gutter-large auto;
    position: relative;
    background-color: $color-overlay;
    h2 {
      margin-bottom: $gutter;
    }
  }
  &__quote {
    position: absolute;
    top: $gutter;
    left: $gutter;
    width: 6rem;
    height: auto;
  }
  &__paragraph {
    color: $color-white;
    font-weight: bold;
    min-width: 600px;
  }
  &__carousel {
    min-width: 600px;
  }
  .slick-dots {
    display: flex !important;
    margin-top: $gutter;
    justify-content: flex-end;
    .slick-active {
      div {
        background-color: $color-white;
      }
    }
  }
  .slick-list {
    margin-left: -$gutter-large;
    margin-right: -$gutter-large;
  }
  .slick-slide>div {padding: 0 $gutter-large;}
}

@media(max-width: $breakpoint) {
  .temoignages {
    padding: $gutter;
    &__text {
      padding: $gutter;
      width: 100%;
    }
    &__quote {
      top: $gutter/4;
      left: $gutter/4;
      width: 3rem;
    }
    &__paragraph {
      width: 100%;
      min-width: unset;
    }
    &__carousel {
      min-width: unset;
    }
    &__background {
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      &--image {
        position: relative;
        width: 100%;
      }
    }
    .slick-dots {
      justify-content: space-evenly;
    }
  }
}
