@import '../../../styles/_vars';

.formations-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $gutter-large;
  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: $gutter;
    img {
      width: 50px;
    }
    p {
      text-align: center;
      line-height: 1.2em;
      color: $color-button;
    }
  }
  &__carousel {
    margin-bottom: $gutter;
  }
  &__dot {
    width: .8em;
    height: .8em;
    border-radius: .5em;
    background-color: $color-button;
    margin: 2em;
  }
  &__horizontal-line {
    position: absolute;
    top: calc(80px + 2.3em);
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-main;
    z-index: -1;
  }
  .slick-slider {
    width: calc(100% - 60px);
  }
  .slick-arrow {
    position: absolute;
    top: calc(65px + 2.3em);
    width: 30px;
    height: 30px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .slick-prev {
    left: -30px;
    transform: rotate(180deg);
  }
  .slick-next {
    right: -30px;
  }
}

@media(max-width: $breakpoint) {
  .formations-section {
    &__carousel {
      margin: 0;
    }
  }
}
