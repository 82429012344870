@import '../../../styles/_vars';

.cards {
  &__cards {
    margin-top: $gutter-large;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: $breakpoint) {
  .cards {
    &__cards {
      flex-wrap: wrap;
    }
  }
  .card {
    width: 100%;
    margin-bottom: $gutter;
  }
}
