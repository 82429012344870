@import '../../../styles/_vars';

.not-found {
  padding-top: $gutter;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__message {
    width: 80%;
    margin: $gutter $margin 0 $margin;
    text-align: center;
  }
  .button {
    display: block;
    margin: $gutter 0;
    width: fit-content;
  }
}
