@import '../../../styles/_vars';

.header__navbar {
  position: sticky;
  top: 0;
  z-index: 9999;
  flex: 1;
  display: flex;
  align-items: stretch;
  padding: 0 $margin;
  background-color: $color-white;
  height: 6rem;
  box-shadow: $shadow-dark;
  #logo, .logo {
    height: 100%;
  }
  span {
    margin-left: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    display: flex;
    &:hover {
      .dropdown {
        visibility: visible;
        opacity: 1;
      }
    }
    .active {
      color: $color-accent;
    }
    a, div {
      color: $color-grey;
      height: 3em;
      display: flex;
      align-items: center;
      &:hover {
        color: $color-accent;
        .chevron {
          fill: $color-accent;
        }
      }
      svg {
        margin-left: .5em;
      }
    }
    .chevron {
      fill: $color-grey;
      &--active {
        fill: $color-accent;
      }
    }
  }
  .header__spacing {
    flex: 1;
  }
  .dropdown {
    visibility: hidden;
    opacity: 0;
    margin: 0;
    flex-direction: column;
    align-items: stretch;
    border-top: 4px solid $color-accent;
    position: absolute;
    top: calc(100% - 4px);
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;
    width: max-content;
    box-shadow: $shadow-dark;
    opacity: 0;
    transition: all .3s;
    span {
      margin: 1em 0 0 0;
      &:last-child {
        margin-bottom: 1em;
      }
    }
    a {
      padding: 0 3em;
      justify-content: center;
    }
  }
};

.header__hamburger {
  width: 3.6rem;
  height: 3.25rem;
  position: relative;
  cursor: pointer;
  padding: 1rem;
  margin-right: -1rem;
  align-self: center;
  span {
    display: block;
    position: absolute;
    margin-left: 0;
    width: 1.6rem;
    height: .25rem;
    background-color: $color-button;
    border-radius: .2rem;
    transition: transform .2s ease, background-color .2s ease;
    &:nth-child(1) {
      top: 1;
    }
    &:nth-child(2) {
      top: 1.5rem;
    }
    &:nth-child(3) {
      top: 1.5rem;
    }
    &:nth-child(4) {
      top: 2rem;
    }
  }
}

#hamburger-toggle {
  display: none;
  &:checked {
    + .header__hamburger span {
      background-color: $color-button;
      &:nth-child(1) {
        transform: scaleX(0.01) translateY(.6rem);
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        transform: scaleX(0.01) translateY(-.6rem);
      }
    }
  }
}

.header__mobile-menu {
  position: fixed;
  top: 6rem; right: 0; bottom: 0; left: 0;
  height: calc(100vh - 6rem);
  background-color: $color-white;
  transform: translateX(150%);
  transition: transform .3s;
  padding: $margin;
  span {
    margin: 0;
    &> a {
      padding: $gutter/2;
      padding-left: 0;
      height: auto;
      line-height: 1.5rem;
    }
  }
  .header__sublink {
    padding: $gutter/4 0 $gutter/4 $gutter/2;
  }
  &--visible {
    transform: translateX(0);
  }
  .active {
    color: $color-accent;
  }
}
