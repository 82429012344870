@import '../../../styles/_vars';

.banner {
  position: relative;
  width: 100%;
  height: calc(100vh - 7.6rem);
  &__content {
    padding: 0 $margin;
    width: 100%;
    height: calc(100vh - 7.6rem);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    z-index: 1;
    h1 {
      text-shadow: 2px 2px 12px #000;
    }
  }
}

@media(max-width: $breakpoint) {
  .banner {
    height: calc(100vh - 6rem);
    &__content {
      height: calc(100vh - 6rem);
    }
  }
}
