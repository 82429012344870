@import '../../styles/_vars';

.card {
  margin: 0 $gutter/2;
  width: 100%;
  &__wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 35%;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    &:hover {
      .card__text {
        transform: translateY(35%);
        box-shadow: $shadow-dark-large;
      }
      .card__title, .card__link {
        color: $color-accent;
      }
      .chevron {
        fill: $color-accent;
      }
    }
  }
  &__picture {
    position: absolute;
    z-index: 1;
    display: block;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__text {
    position: absolute;
    z-index: 2;
    top: 5%; right: 5%; bottom: 5%; left: 5%;
    height: 90%;
    width: 90%;
    transform: translateY(40%);
    box-shadow: $shadow-dark;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    padding: $gutter;
    border-radius: $radius;
    transition: all .3s;
  }
  &__link {
    display: flex;
    align-items: center;
    color: $color-main;
    transition: color .3s;
    line-height: 1em;
    .chevron {
      fill: $color-main;
      transition: fill .3s;
    }
    svg {
      margin-left: .5rem;
    }
  }
  &__title {
    transition: color .3s;
  }
  &__description {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: $gutter/2 0;
  }
  &__price-container {
    .card__price {
      font-size: 4rem;
      line-height: 1em;
      text-align: center;
    }
    .card__description {
      text-align: center;
      margin-top: 0;
    }
  }
}

@media (max-width: $breakpoint) {
  .card {
    &__text {
      transform: translateY(20%);
    }
    &__price-container {
      .card__price {
        font-size: 2rem;
      }
    }
  }
}
